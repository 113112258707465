import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import Header from '../components/header'
import Intro from '../components/sections/intro'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'

const SinglePage = ({ location }) => (
  <Layout phone="855-504-2764" location={location}>
    <SEO
      title="Medicare Offers"
      keywords={[`medicare`, `health`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone="855-504-2764" />
    <Intro
      title="Find Affordable Medicare Insurance in 2 Minutes"
      showSubTitle={false}
      colType="left-col"
    >
      <ApplySingle className="form-box full-width" />
    </Intro>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default SinglePage
